import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Hyperlink } from "luminopix-ui-common"
import vangogh from "./images/vangogh-selfportrait.png"
import { FeaturedItem } from "./FeaturedItem"

export const HomePage = () => {
  return (
    <>
      <Box sx={{objectFit:"contain"}}>
        <Box sx={{position: "absolute", width: "50%", height: "auto", margin: "32px"}}>
          <Typography variant="h1">Welcome to Luminopix</Typography>
          <Typography variant="body1"><br/>Luminopix allows you to create your own custom-shaped lithophanes easily.</Typography>
          <Typography variant="body1">Start a new lithophane from scratch at <Hyperlink to="https://studio.luminopix.com/template?id=blank">Luminopix Studio</Hyperlink>.</Typography>
          <Typography variant="body1">Or browse the <Hyperlink to="https://gallery.luminopix.com/">Luminopix Gallery</Hyperlink> and choose an item to use as a template or to download as is.</Typography>
        </Box>
        <Box sx={{position: "absolute", zIndex: -1, width: "auto", height: "90%", right:"0"}}>
          <FeaturedItem galleryItemId="gogh-portrait-straw-hat" itemDescription="Self-Portrait with a Straw Hat, by Van Gogh" image={vangogh} />
        </Box>
      </Box>
    </>
  )
}
