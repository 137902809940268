import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Error404 } from 'luminopix-ui-common';
import { HomePage } from './HomePage';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { theme, NavBar } from 'luminopix-ui-common';
import { AnalyticsProvider } from './analytics';

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
