import { Box, Typography } from "@mui/material"
import { Hyperlink } from "luminopix-ui-common"

export const FeaturedItem = ({galleryItemId, itemDescription, image}) => {
  return (
    <Box sx={{width: "100%", height: "100%", boxSizing: "border-box"}}>
      <img src={image} alt={itemDescription} style={{maxWidth: "100%", maxHeight: "100%", objectFit:"contain", imageRendering:"smooth"}} />
      <Box sx={{position: "absolute", width:"50%", bottom: "16px", right: "16px"}}>
        <Typography variant="featuredItem" align="right" fontSize="200%">{itemDescription}</Typography>
        <Typography variant="featuredItem" align="right">View in <Hyperlink to={`https://gallery.luminopix.com/template?templateId=${galleryItemId}`}>Luminopix Gallery</Hyperlink>.</Typography>
        <Typography variant="featuredItem" align="right">Download, edit shape or change image in <Hyperlink to={`https://studio.luminopix.com/template?id=${galleryItemId}`}>Luminopix Studio</Hyperlink>.</Typography>
      </Box>
    </Box>
  )
}
